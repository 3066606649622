@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
* {
  outline: none !important;
}
::root {
  --blue: #000;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  background: #dddddd29;
}

img {
  max-width: 100%;
}

.no-pad {
  padding: 0 !important;
}

.header {
  background: #ffffff;
  box-shadow: 0px 2px 6px #0000000f;
}

.branding {
  background: #000;
  display: block;
  height: 100%;
}

.branding h3 {
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 2px;
  padding: 25px 25px;
  margin: 0;
}

.header-content {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.search-bar {
  width: 80%;
}
.header-profile {
  width: 20%;
}

.search-bar input {
  width: 100%;
  border: 0px;
  text-indent: 40px;
  margin-left: 70px;
  padding: 10px 0;
}

.header-profile ul {
  margin: 0;
  padding: 0;
  border-right: 1px solid #ebebf2;
  margin-right: 20px;
  display: flex;
}
.header-profile ul li {
  list-style: none;
  display: inline-block;
}
.header-profile ul li a {
  padding-right: 40px;
}
.header-profile ul li a img {
  width: 18px;
}

.header-profile {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.header-profile img.avatar {
  width: 50px;
  border-radius: 50%;
  margin-left: 15px;
}

.header-profile .dropdown button {
  background: transparent !important;
  border: 0px !important;
  color: #4d4f5c !important;
  box-shadow: none !important;
}

.nav-sidebar {
  background: #424040;
  position: fixed;
  top: 71px;
  height: 100vh;
  width: 16.666667%;
}
.nav-sidebar ul {
  margin: 0;
  padding: 0;
}
.nav-sidebar ul li {
  list-style: none;
}
.nav-sidebar ul li a {
  color: #fff;
  display: block;
  padding: 20px 25px;
  text-decoration: none;
  transition: 0.3s;
}
.nav-sidebar ul li a:hover {
  background: #565454;
}
.nav-sidebar ul li a.active {
  background: #565454;
  border-left: 4px solid #000;
}
.nav-sidebar ul li a span {
  margin-left: 10px;
}
.nav-sidebar ul li a img {
  margin-top: -5px;
}

.header {
  position: sticky;
  top: 0;
  z-index: 999;
}

.layout-wrapper {
  margin: 30px;
}

.dashboard-wrapper {
  background: #f9f9f9;
  padding: 25px 30px;
  box-shadow: 0px 0px 6px #0000000f;
}

.dash-title {
  margin: 0;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #43425d;
}

.overview-block {
  box-shadow: 0px 10px 10px #0000000f;
  background: #ffffff;
  padding: 25px 30px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}
.overview-block p {
  color: #43425d;
  opacity: 0.5;
  margin-bottom: 20px;
}
.overview-block h4 {
  color: #4d4f5c;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0;
}
.overview-block span {
  color: #ff4141;
  font-size: 13px;
  font-weight: bold;
}
.overview-block img {
  align-self: flex-end;
}

.dashboard-wrapper .row {
  margin-bottom: 30px;
}
.dashboard-wrapper .row:first-child {
  margin-bottom: 0px;
}

.application-block {
  box-shadow: 0px 2px 6px #0000000f;
  background: #ffffff;
  padding: 20px 30px;
  height: 100%;
}

.block-head {
  margin-bottom: 40px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.block-head h3 {
  font-weight: 600;
  font-size: 24px;
  margin: 0;
  margin-right: 30px;
}

.app-option {
  width: 30%;
  justify-self: flex-end;
}

.team-progress {
  margin-bottom: 25px;
}
.team-progress p {
  margin-bottom: 5px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  color: #4d4f5c;
  opacity: 0.7;
}
.team-progress .progress {
  height: 10px;
}
.team-progress .progress-bar {
  border-radius: 10px;
}
.team-progress:nth-child(1) .progress-bar {
  background-color: #a3a1fb;
}
.team-progress:nth-child(2) .progress-bar {
  background-color: #5ee2a0;
}
.team-progress:nth-child(3) .progress-bar {
  background-color: #55d8fe;
}
.team-progress:nth-child(4) .progress-bar {
  background-color: #fec163;
}
.team-progress:nth-child(5) .progress-bar {
  background-color: #ffa177;
}

.CircularProgressbar {
  width: 120px !important;
  margin-left: 25px;
}

.online-status {
  margin-right: 60px;
  align-self: center;
}

.single-stat {
  position: relative;
}
.single-stat:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #edecfe;
  left: -20px;
  border-radius: 50%;
  top: 8px;
}

.single-stat:last-child:before {
  background: #a4a1fb;
}

.single-stat p {
  color: #43425d !important;
  font-size: 16px !important;
  opacity: 1;
  margin-bottom: 0;
}

.single-stat:first-child p {
  margin-bottom: 10px;
}

.single-stat p span {
  color: #4d4f5c !important;
  font-size: 14px !important;
  opacity: 0.5;
}

/* Login */

.login-wrapper {
  background: #fff;
  min-height: 100vh;
}

.login-logo {
  text-align: center;
}

.login-logo img {
  max-width: 310px;
}

.login-logo p {
  color: #4d4f5c;
  opacity: 0.5;
  margin-top: 40px;
  font-size: 18px;
}
.checkbox-inp,
.checkbox-inp input {
  margin-bottom: 0 !important;
}
.login-form {
  padding-left: 80px;
  padding-right: 80px;
}

.login-form form {
  margin-top: 50px;
}

.login-form input {
  margin-bottom: 15px;
}

.login-form button {
  width: 100%;
  display: block;
  border: 0px;
  border-radius: 4px;
  background: #000;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  padding: 8px;
  transition: 0.3s;
  outline: none;
  text-decoration: none;
}

.login-form button:hover {
  background: #666;
  text-decoration: none;
}

.login-control {
  width: 100%;
  border: 0px;
  border-bottom: 2px solid #e9e9f0;
  padding: 15px 10px;
  margin-bottom: 30px;
  transition: 0.3s;
}
.login-control:focus {
  border-bottom: 2px solid #000;
}

.forgot-pass {
  margin-top: 15px;
  display: inline-block;
}

.auth-actions {
  margin: 20px -15px;
  margin-bottom: 40px;
}

.white-wrapper {
  background: #fff;
}

.daily-activity-table {
  padding: 20px 30px;
}

.activity-button {
  border: 1px solid #7070704f;
  color: #4d4f5c;
  padding: 5px 16px;
  border-radius: 40px;
  transition: 0.3s;
}
.activity-button:hover {
  text-decoration: none;
  color: #fff;
  background: #000;
  border-color: #000;
  box-shadow: 0px 2px 6px #0000000f;
}

.daily-activity-table thead {
  background: #f5f6fa;
}
.daily-activity-table .table thead th {
  border-bottom: 0px;
  border-top: 0px;
  font-weight: 600;
}

.activity-button-col {
  width: 20%;
}

.table td,
.table th {
  border-top: 1px solid #f1f1f3;
  padding: 15px 20px;
}

.daily-activity-table .table {
  margin-bottom: 0;
}

.mt-40 {
  margin-top: 40px;
}

.daily-activity-option {
  width: 100%;
  margin-left: 40px;
}

.daily-activity-select {
  width: 35%;
  display: flex;
}

.daily-activity-search {
  width: 40%;
}

.daily-activity-search input {
  width: 100%;
  border: 0px;
  text-indent: 40px;
  margin-left: 70px;
  padding: 10px 0;
}

.da-wrapper {
  padding: 35px 30px;
}
.table-search {
  width: 100%;
}

.table-search input {
  width: 100%;
  border: 0px;
  text-indent: 40px;
  padding: 10px 0;
}

.working-hour-table th {
  vertical-align: top !important;
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.wh-profile {
  display: flex;
  align-items: center;
}

.wh-profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.wh-caption {
  margin-left: 0;
}
.wh-caption p {
  font-weight: 600;
  margin: 0;
}
.wh-caption span {
  font-size: 14px;
}

.table td,
.table th {
  vertical-align: middle;
}

.employee-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.employee-title .dash-title {
  margin-bottom: 0;
}

.employee-add-btn {
  background: #007bff;
  color: #fff;
  padding: 6px 18px;
  margin-left: 15px;
  margin-top: 5px;
  transition: 0.3s;
  border-radius: 4px;
}
.employee-add-btn:hover {
  background: #145aa5;
  color: #fff;
  text-decoration: none;
}

.employee-add-btn svg {
  margin-right: 3px;
  margin-top: -4px;
  margin-left: -5px;
}

.action-btn {
  margin-right: 20px;
}

.single-activity-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-left: -15px;
  margin-right: -15px;
}
.employee-title {
  margin-left: -15px;
  margin-right: -15px;
}
.single-activity-header .dash-title {
  margin-bottom: 0;
}

.user-id-block {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 15px 35px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.user-id-block img {
  width: 90px;
  border-radius: 50%;
  margin-right: 20px;
}

.user-id-block p {
  margin-bottom: 0;
  color: #333333ad;
  font-size: 16px;
}
.user-id-block h5 {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
}
.emp-form-row {
  margin-bottom: 20px !important;
  padding-bottom: 25px;
  border-bottom: 1px solid #dddddd73;
}
.emp-form-row:last-child {
  border-bottom: 0px;
}

.add-emp-btn-group .employee-add-btn {
  margin-left: 15px;
  padding: 10px 25px;
}

.add-emp-btn-group .cancel {
  background: #bdbdbd82;
  color: #000;
}
.add-emp-btn-group .cancel:hover {
  background: #bdbdbd;
}

label.form-label {
  font-weight: 600;
}

.react-date-picker {
  width: 100%;
  height: 40px;
}
.react-date-picker__inputGroup,
.react-time-picker__inputGroup {
  text-indent: 5px;
}
.react-date-picker__wrapper,
.react-time-picker__wrapper {
  border-color: #ced4da !important;
}
.react-time-picker {
  width: 100%;
  height: 40px;
}

.cap-trigger {
  cursor: pointer;
  font-weight: 600;
}
.cap-trigger:hover {
  color: #666;
}
.modal-title h5 {
  margin: 0;
}

button {
  border: 0px;
}

.class-cancel-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  height: 100%;
  margin-top: 40px;
}

.cancel-button-popup {
  display: flex;
}
.cancel-button-popup button {
  background: #bdbdbd82;
  color: #000;
  margin-left: 15px;
  padding: 10px 25px;
  margin-top: 5px;
  transition: 0.3s;
  border-radius: 4px;
}
.cancel-button-popup button:hover {
  background: #bdbdbd;
}

.cancel-skylight .skylight-dialog {
  min-height: 200px !important;
}

.cancel-submit {
  background: red !important;
  color: #fff !important;
}
.cancel-submit:hover {
  background: #cc0b0b !important;
}

.class-members-popup {
  padding: 20px;
}
.class-members-popup h3 {
  margin: 20px 0;
  margin-top: 0;
  font-size: 24px;
}
.members-skylight .skylight-dialog {
  min-height: auto !important;
}

.class-action-controller {
  display: flex;
}

.class-action-controller button {
  margin-left: 5px;
}

.approval-skylight .skylight-dialog {
  min-height: 220px !important;
}

.react-time-picker__clock {
  display: none;
}

.recur-module {
  display: flex;
  align-items: center;
}

.recur-module p {
  margin-bottom: 0;
  font-weight: 600;
  margin-top: 10px;
}

.css-xjimrv-Switch {
  width: 40px !important;
  height: 25px !important;
  margin-bottom: 0;
  margin-top: 13px;
  margin-left: 15px;
}

.css-jfsaru-Switch,
.css-153wf2s-Switch {
  border-radius: 16px !important;
}
.css-19a995p-Switch {
  right: 17px !important;
  border-radius: 16px !important;
}
.css-1v96km2-Switch {
  left: 17px !important;
  border-radius: 16px !important;
}

.dashboard-wrapper .row {
  width: 100%;
}

.class-accordion {
  width: 100%;
}
.class-accordion p {
  margin-bottom: 0;
}
.class-accordion-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.class-accordion-toggle div {
  width: 25%;
}
.class-accordion-toggle div:last-child {
  width: 5%;
}

.form-check-inline {
  margin-right: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
}